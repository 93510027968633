import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    VStack,
} from '@chakra-ui/react';
import {CasperAPI} from "../APIs/CasperAPI";

interface StatusResponse {
    status: number;
    message: string;
}

const StatusPage: React.FC = () => {
    const [status, setStatus] = useState<StatusResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchHealthStatus = async () => {
            try {
                const res = await CasperAPI.health();
                if (res && res.status) {
                    if (res.status === 200) {
                        setStatus({ status: res.status, message: 'All systems are operational.' });
                    } else if (res.status === 503) {
                        setStatus({ status: res.status, message: 'Service is currently unavailable.' });
                    } else {
                        setStatus({ status: res.status, message: 'There are some issues with the service.' });
                    }
                } else {
                    setError('Unexpected response from the server.');
                }
            } catch (err) {
                setError('Failed to fetch status. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchHealthStatus().then();
    }, []);

    if (loading) {
        return (
            <Box textAlign="center" mt="20">
                <Spinner size="xl" />
                <Text mt="4">Checking system status...</Text>
            </Box>
        );
    }

    if (error) {
        return (
            <Alert status="error">
                <AlertIcon />
                <VStack align="start">
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </VStack>
            </Alert>
        );
    }

    return (
        <Box textAlign="center" mt="20">
            {status && (
                <Alert status={status.status === 200 ? 'success' : 'warning'}>
                    <AlertIcon />
                    <VStack align="start">
                        <AlertTitle>{status.status === 200 ? 'Success' : 'Warning'}</AlertTitle>
                        <AlertDescription>{status.message}</AlertDescription>
                    </VStack>
                </Alert>
            )}
        </Box>
    );
};

export default StatusPage;