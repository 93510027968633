// axiosInstance.ts
import axios from "axios";
import {auth} from "../utils/firebase/firebaseConfig";

const FLASK_API_ADDRESS = "https://api.casprep.net";
const FLASK_LOCAL_ADDRESS = "http://127.0.0.1:6020";

const axiosInstance = axios.create({
    baseURL: FLASK_API_ADDRESS,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(async (config) => {
    const user = auth.currentUser;
    if (user) {
        const token = await user.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;