import * as React from "react";
import {
    Box,
    Button,
    Textarea,
    Text,
    useColorModeValue,
    VStack,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepIcon,
    StepNumber,
    StepTitle,
    StepSeparator,
    useSteps,
} from "@chakra-ui/react";
import { useState } from "react";
import { CasperAPI } from "../APIs/CasperAPI";
import {
    CasperScenario,
    CasperInteraction,
} from "../models/Models";
import CasperInteractionComp from "./CasperInteractionComp";


interface EvaluateCasperBoxProps {
    scenario: CasperScenario;
}

const EvaluateCasperBoxV1: React.FC<EvaluateCasperBoxProps> = ({ scenario }) => {
    const [answers, setAnswers] = useState<string[]>(
        Array(scenario.questions.length).fill("")
    );
    const [interaction, setInteraction] = useState<CasperInteraction | null>(
        null
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Initialize the stepper
    const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: scenario.questions.length,
    });

    const handleAnswerChange =
        (index: number) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const newAnswers = [...answers];
            newAnswers[index] = event.target.value;
            setAnswers(newAnswers);
        };

    const handleNext = async () => {
        if (activeStep < scenario.questions.length - 1) {
            goToNext();
        } else {
            await handleEvaluateAnswer();
        }
    };

    const handleEvaluateAnswer = async () => {
        setLoading(true);
        setError(null);
        try {
            // Assuming CasperAPI.evaluate_casper_scenario returns a CasperInteraction
            const res: CasperInteraction = await CasperAPI.evaluate_casper_scenario(
                scenario,
                answers
            );
            console.log("Response:", res);
            setInteraction(res);
        } catch (err) {
            console.error("Evaluation error:", err);
            setError("An error occurred during evaluation.");
        }
        setLoading(false);
    };

    const boxBg = useColorModeValue("white", "gray.700");
    const responseBg = useColorModeValue("gray.50", "gray.800");
    const borderColor = useColorModeValue("gray.200", "gray.600");

    // Function to reset the component for a new interaction
    const handleReset = () => {
        setAnswers(Array(scenario.questions.length).fill(""));
        setInteraction(null);
        setActiveStep(0);
    };

    return (
        <Box
            p={6}
            bg={boxBg}
            borderRadius="md"
            w={{ base: "90%", md: "80%", lg: "70%" }}
            maxW="4xl"
            mx="auto"
            mt={8}
            shadow="md"
            borderWidth="1px"
            borderColor={borderColor}
        >
            <VStack spacing={6} align="stretch">
                {/* Display the scenario */}
                <Box
                    p={4}
                    bg={responseBg}
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor={borderColor}
                >
                    <Text fontSize="2xl" fontWeight="bold" mb={2}>
                        Scenario
                    </Text>
                    <Text fontSize="md">{scenario.scenario}</Text>
                </Box>

                {/* Conditional rendering based on whether interaction is completed */}
                {interaction ? (
                    <CasperInteractionComp
                        interaction={interaction}
                        scenario={scenario}
                    />
                ) : (
                    <>
                        {/* Stepper to indicate progress */}
                        <Stepper index={activeStep} orientation="horizontal" mb={6}>
                            {scenario.questions.map((_, index) => (
                                <Step key={index}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>

                                    <Box flexShrink="0">
                                        <StepTitle>{`Question ${index + 1}`}</StepTitle>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>

                        {/* Display the current question and answer textarea */}
                        <Box>
                            <Text fontSize="xl" fontWeight="bold" mb={2}>
                                {`Question ${activeStep + 1}`}
                            </Text>
                            <Text fontSize="md" mb={4}>
                                {scenario.questions[activeStep]}
                            </Text>
                            <Textarea
                                value={answers[activeStep] || ""}
                                onChange={handleAnswerChange(activeStep)}
                                placeholder={`Type your answer to Question ${
                                    activeStep + 1
                                } here`}
                                variant="filled"
                                size="lg"
                                resize="vertical"
                                minH="120px"
                            />
                            <Text
                                alignSelf="flex-end"
                                fontSize="sm"
                                color="gray.500"
                                mt={1}
                                textAlign="right"
                            >
                                {answers[activeStep]?.length ?? 0}/3000 characters
                            </Text>
                        </Box>

                        {/* Navigation Buttons */}
                        <Box display="flex" justifyContent="space-between" mt={6}>
                            {activeStep > 0 && (
                                <Button
                                    onClick={goToPrevious}
                                    colorScheme="gray"
                                    width="48%"
                                    size="lg"
                                    isDisabled={loading}
                                >
                                    Back
                                </Button>
                            )}
                            <Button
                                onClick={handleNext}
                                colorScheme="blue"
                                isLoading={loading}
                                loadingText={
                                    activeStep === scenario.questions.length - 1
                                        ? "Submitting"
                                        : "Loading"
                                }
                                width={activeStep > 0 ? "48%" : "100%"}
                                size="lg"
                            >
                                {activeStep === scenario.questions.length - 1
                                    ? "Submit"
                                    : "Next"}
                            </Button>
                        </Box>

                        {/* Display error */}
                        {error && (
                            <Text color="red.500" mt={2}>
                                {error}
                            </Text>
                        )}
                    </>
                )}
            </VStack>
        </Box>
    );
};

export default EvaluateCasperBoxV1;