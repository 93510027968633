import * as React from "react";
import {
    Box,
    Button,
    Text,
    useColorModeValue,
    CircularProgress,
    CircularProgressLabel, Wrap, WrapItem, Badge,
} from "@chakra-ui/react";
import { CasperScenario, CasperInteraction } from "../models/Models";
import {useNavigate} from "react-router-dom";

interface CasperInteractionProps {
    interaction: CasperInteraction;
    scenario: CasperScenario;
}

const CasperInteractionComp: React.FC<CasperInteractionProps> = ({ interaction, scenario }) => {
    const navigate = useNavigate();
    const responseBg = useColorModeValue('secondary.50', 'secondary.800');
    const borderColor = useColorModeValue('primary.200', 'primary.700');

    const handleReset = () => {
        console.log(interaction)
        navigate("/answer-a-question/" + interaction.casper_scenario.toHexString())
    };

    return (
        <Box>
            {interaction.interaction_details.map((detail, index) => (
                <Box
                    key={index}
                    mb={6}
                    p={6}
                    bg={responseBg}
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor={borderColor}
                >
                    <Text fontSize="xl" fontWeight="bold" mb={4}>
                        {`Question ${index + 1}`}
                    </Text>
                    <Text fontSize="lg" fontWeight="semibold" mb={2}>
                        Question
                    </Text>
                    <Text mb={4}>{scenario.questions[index]}</Text>
                    <Text fontSize="lg" fontWeight="semibold" mb={2}>
                        Your Answer
                    </Text>
                    <Text mb={4}>{detail.answer}</Text>
                    <Text fontSize="lg" fontWeight="semibold" mb={2}>
                        Feedback
                    </Text>
                    <Text mb={4}>{detail.answer_feedback}</Text>
                    <Text fontSize="lg" fontWeight="semibold" mb={2}>
                        Score
                    </Text>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress
                            value={detail.score}
                            max={4}
                            size="120px"
                            color="primary.500"
                            thickness="8px"
                        >
                            <CircularProgressLabel fontSize="lg" fontWeight="bold">
                                {detail.score}/4
                            </CircularProgressLabel>
                        </CircularProgress>
                    </Box>
                    <Text fontSize="lg" fontWeight="semibold" mb={2}>
                        Top hit on characteristic
                    </Text>
                    <Badge>
                        {detail.top_hit_on_characteristic}
                    </Badge>

                    <Text fontSize="lg" fontWeight="semibold" mb={2}>
                        Top missed on characteristic
                    </Text>
                    <Badge>
                        {detail.top_missed_on_characteristic}
                    </Badge>
                </Box>
            ))}
            <Button
                size="sm"
                colorScheme="primary"
                variant="outline"
                onClick={handleReset}
                _hover={{ bg: 'primary.600', color: 'white' }}
            >
                Try Again
            </Button>
        </Box>
    );
};

export default CasperInteractionComp;
