import * as React from 'react';
import { ChakraProvider, ColorModeScript, Spinner, Flex } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Settings from './screens/Settings';
import SidebarWithHeader from './components/SidebarWithHeader';
import { AuthProvider, useAuth } from './utils/firebase/authContext';
import Dashboard from "./screens/Dashboard";
import PageNotFound from './screens/PageNotFound';
import {PricingPage} from "./screens/Payments/PricingPage";
import StatusPage from "./screens/StatusPage";
import { ComingSoon } from "./screens/ComingSoon";
import theme from "./theme";
import InteractionHistory from "./screens/Casper/InteractionsHistory";
import AskQuestionPage from "./screens/Casper/AskQuestionPage";
import ScenarioList from "./screens/Casper/ScenariosList";
import ProfileScreen from "./screens/User/ProfileScreen";
import Login from "./screens/Auth/Login";
import Signup from "./screens/Auth/Signup";
import FeatureComingSoon from "./components/FeatureComingSoon";
import AdminPortal from "./screens/Admin/AdminPortal";
import PrivacyPolicy from "./screens/Legal/PrivacyPolicy";
import LegalPolicy from "./screens/Legal/LegalPolicy";
import ContactUsPage from "./screens/ContuctUsPage";
import {PlanPage} from "./screens/Payments/PlanPage";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const { user, loading } = useAuth();

    if (loading) {
        return (
            <Flex justify="center" align="center" height="100vh" bg="background">
                <Spinner size="xl" color="primary" />
            </Flex>
        );
    }

    return user ? children : <Navigate to="/login" />;
};

// Redirect to coming-soon page if user is not authenticated
const RedirectIfLoggedIn = ({ children }: { children: JSX.Element }) => {
    const { user, loading } = useAuth();

    if (loading) {
        return (
            <Flex justify="center" align="center" height="100vh" bg="background">
                <Spinner size="xl" color="primary" />
            </Flex>
        );
    }

    return user ? <Navigate to="/" /> : children;
};

const ProtectedLayout = () => (
    <SidebarWithHeader>
        <Outlet />
    </SidebarWithHeader>
);

export const App = () => (
    <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <AuthProvider>
            <Router>
                <Routes>
                    {/* Publicly accessible routes */}
                    <Route path="/coming-soon" element={<ComingSoon />} />
                    <Route path="/home" element={<ComingSoon />} />
                    <Route path="/status" element={<StatusPage />} />

                    {/* Restrict access to login/signup if already logged in */}
                    <Route path="/login" element={<RedirectIfLoggedIn><Login /></RedirectIfLoggedIn>} />
                    <Route path="/signup" element={<RedirectIfLoggedIn><Signup /></RedirectIfLoggedIn>} />

                    {/* Protected routes for logged-in users */}
                    <Route path="/" element={<PrivateRoute><ProtectedLayout /></PrivateRoute>}>
                        <Route index element={<Dashboard />} />

                        {/*Casper Pages*/}
                        <Route path="ask-a-question" element={<AskQuestionPage />} />
                        <Route path="answer-a-question" element={<ScenarioList />} />
                        <Route path="answer-a-question/:scenario_id" element={<ScenarioList />} />
                        <Route path="history" element={<InteractionHistory />} />
                        <Route path="video" element={<FeatureComingSoon/>} />
                        <Route path="explore" element={<Settings />} />

                        {/*Admin Pages*/}
                        <Route path="_admin/*" element={<AdminPortal />} />

                        {/*User Pages*/}
                        <Route path="settings" element={<Settings />} />
                        <Route path="profile" element={<ProfileScreen />} />
                        <Route path="pricing" element={<PricingPage />} />
                        <Route path="my-plan" element={<PlanPage/>} />

                        {/*Legal*/}
                        <Route path="privacy" element={<PrivacyPolicy/>} />
                        <Route path="legal" element={<LegalPolicy/>} />
                        <Route path="contact-us" element={<ContactUsPage/>} />
                    </Route>

                    {/* Fallback for unknown routes */}
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Router>
        </AuthProvider>
    </ChakraProvider>
);

export default App;