import FLASK_HTTPS from "./_FLASK_API";
import { AxiosResponse } from "axios";
import { CasperInteraction, CasperScenario } from "../models/Models";
import { ErrorHandler } from "../utils/error";

export namespace CasperAPI {
    let route_name = "/casper";

    // Ask Casper Scenario V1
    export const ask_casper_scenario_v1 = async (scenario: CasperScenario): Promise<string | undefined> => {
        try {
            const res = await FLASK_HTTPS.post(`${route_name}/api/v1/ask_casper_scenario_v1`, {
                scenario: scenario._id,
            });
            return res.data as string;
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Error asking Casper scenario');
            return undefined;
        }
    };

    // Evaluate Casper Answer V1
    export const evaluate_casper_scenario = async (
        scenario: CasperScenario,
        answers: string[]
    ): Promise<CasperInteraction> => {
        try {
            const res = await FLASK_HTTPS.post(`${route_name}/api/v1/evaluate_casper_scenario`, {
                scenario: scenario._id,
                answers: answers,
            });
            return new CasperInteraction(res.data);
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Error evaluating Casper answer');
            // Throw an error to ensure the calling code handles it
            throw new Error('Failed to evaluate Casper answer');
        }
    };

    // Get Casper Scenarios V1
    export const get_casper_scenarios_v1 = async (): Promise<CasperScenario[]> => {
        try {
            const res = await FLASK_HTTPS.get(`${route_name}/api/v1/get_casper_scenarios_v1`);
            return res.data.scenarios.map((scenarioData: any) => new CasperScenario(scenarioData));
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Error fetching Casper scenarios');
            // Return an empty array to avoid undefined
            return [];
        }
    };

    // Get Casper Scenario
    export const get_casper_scenario = async (scenario_id: string): Promise<CasperScenario> => {
        try {
            const res = await FLASK_HTTPS.get(`${route_name}/get_casper_scenario/${scenario_id}`);
            return new CasperScenario(res.data);
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Error fetching Casper scenario');
            // Throw an error to ensure the calling code handles it
            throw new Error('Failed to fetch Casper scenario');
        }
    };

    // bulk_upload_scenarios accepts FormData instead of File
    export const bulk_upload_scenarios = async (formData: FormData): Promise<void> => {
        try {
            const res = await FLASK_HTTPS.post(`${route_name}/bulk_upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return res.data;  // If needed, return the result
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Error uploading CSV file');
            throw new Error('Failed to upload scenarios');
        }
    };

    // Get Self Interactions
    export const get_self_interactions = async (): Promise<CasperInteraction[]> => {
        try {
            const res = await FLASK_HTTPS.get(`${route_name}/get_self_interactions`);
            // Assuming res.data is an array of interaction objects
            return res.data.map((interactionData: any) => new CasperInteraction(interactionData));
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Error fetching interactions');
            // Throw an error to ensure the calling code handles it
            throw new Error('Failed to fetch interactions');
        }
    };

    export interface CasperDashboardData {
        questionsCompleted: {
            title: string;
            labels: string[];
            data: number[];
        };
        averageQuartileScore: {
            title: string;
            labels: string[];
            data: number[];
        };
        customGraph: {
            title: string;
            labels: string[];
            data: number[];
        };
        listData: {
            topMissedCharacteristics: {
                title: string;
                data: Array<{
                    label: string;
                    value: string;
                    trend: 'up' | 'down';
                }>;
            };
            topHitOnCharacteristics: {
                title: string;
                data: Array<{
                    label: string;
                    value: string;
                    trend: 'up' | 'down';
                }>;
            };
            lastAnsweredQuestions: {
                title: string;
                data: string[];
            };
        };
    }

// Fetch Casper dashboard data from the backend
    export const get_casper_dashboard = async (): Promise<CasperDashboardData> => {
        try {
            const res = await FLASK_HTTPS.get(`${route_name}/get_casper_dashboard`);
            // Return the dashboard data as is
            return res.data as CasperDashboardData;
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Error fetching Casper dashboard data');
            // Throw an error to ensure the calling code handles it
            throw new Error('Failed to fetch Casper dashboard data');
        }
    };

    // Health Check
    export const health = async (): Promise<AxiosResponse> => {
        try {
            const res = await FLASK_HTTPS.get(`${route_name}/status`);
            return res;
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Error checking API health');
            // Throw an error to ensure the calling code handles it
            throw new Error('Failed to check API health');
        }
    };
}